export default async function Textos(secciones, idioma) {
  const miscUrl = process.env.REACT_APP_MISC_API || 'http://localhost:3002' 
  if (miscUrl.includes(global.URL_Dominio)) {
    const response = await fetch(`${miscUrl}/texto/${secciones||'-'}&${idioma||'-'}`, { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    .catch(err => {
      throw (err)
    })
    const datos = await response.json()
    return datos.body || {}
  } else {
    console.error(`URL no permitida para el Service Worker: ${miscUrl}`);
    return {};
  }
}